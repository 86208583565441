/* eslint-disable @next/next/no-img-element */
import { fetchTemplate } from '@/utils/isServer';
import Title from '@components2/Landing/Title';
import TitleHead from '@/components/TitleHead';
import CardList from '@/components2/CardList';
import { absoluteServerPath, CDN2, resolveConfigUrl } from '@helpers/api';
import { cacheFetch } from '@helpers/cacheFetch';
import { Button, Collapse, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Image from 'next/image';
import SearchStore from '@/stores/SearchStore';
import { useEffect, useRef, useState, Suspense } from 'react';
import { useMobxStores } from '@/stores';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import Search from '@components/Search';
import NoData from '@/components2/NoData';
import ModalDownloadApp from '@/components2/ModalDownloadApp';
import { shuffleReviews } from '@/utils/fakeReviews';
import MiniInfos from '@/components2/MiniInfos';
import { modalType } from '@/components/Modal';
import { applyTextSubstitution } from '@/helpers/linkhelper';
import { canonicalUrl } from '@/helpers/history';

const SectionItem = dynamic(() => import('@components2/Landing/SectionItem'), {
  ssr: true,
});

const { Panel } = Collapse;

function extractPrefixSuffix(url) {
  let supressFromHome = false;
  let suffix = '';
  let prefix = '/parking/';
  if (url.indexOf('?') != -1) {
    suffix = url.substring(url.indexOf('?'));
  }
  if (url.indexOf('/myapp') != -1) {
    prefix = '/myapp/parking/';
  }
  if (suffix == '?fromhome=true') {
    suffix = '';
    supressFromHome = true;
  }
  return [prefix, suffix, supressFromHome];
}

const SLUG_MAP = {
  'JohnFKennedy-International-Airport-JFK':
    'John-F-Kennedy-International-Airport-JFK',
  'Penn-Station-Parking': 'Penn-Station-Parking-pns',
  'AJacksonville-International-Airport-JAX':
    'Jacksonville-International-Airport-JAX',
  'TFGreen-International-Airport-PVD': 'Providence-International-Airport-PVD',
  'Southwest-Florida-International-Airport-RSW':
    'Fort-Myers-Southwest-Florida-International-Airport-RSW',
  'Tallahassee-International-Airport-Parking-THL':
    'Tallahassee-International-Airport-Parking-TLH',
};

// // This also gets called at build time
export const getServerSideProps: GetServerSideProps = async ({
  req,
  params,
  query,
}) => {
  const UA = req.headers['user-agent'];
  const isMobile = Boolean(
    UA.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
  const isIos = Boolean(UA.match(/iPhone|iPad|iPod/i));
  const { t } = query; // Preview
  const protocol = req.headers['x-forwarded-proto'] || 'http';
  let baseUrl = req ? `${protocol}://${req.headers.host}` : '';

  const checkin = query?.checkin ?? null;
  const checkout = query?.checkout ?? null;

  const configInfo = await cacheFetch(resolveConfigUrl(baseUrl));
  baseUrl = absoluteServerPath(configInfo);
  let landing;
  let CUSTOM_SLUGS = {};
  try {
    CUSTOM_SLUGS = JSON.parse(
      await cacheFetch(`${baseUrl}/api/metadata/loadone/custom_slugs`)
    );
  } catch (e) {
    console.log(e);
  }
  const oriFriendlyUrl = params.friendlyUrl as string;
  let friendlyUrl = oriFriendlyUrl;
  if (CUSTOM_SLUGS[oriFriendlyUrl]) {
    friendlyUrl = CUSTOM_SLUGS[oriFriendlyUrl].target_slug;
  }
  if (SLUG_MAP[friendlyUrl]) {
    friendlyUrl = SLUG_MAP[friendlyUrl];
    const [prefix, suffix] = extractPrefixSuffix(req.url);
    return {
      redirect: {
        destination: prefix + friendlyUrl + suffix,
        permanent: true,
      },
    } as any;
  }
  try {
    if (t === 'preview') {
      const landingResponse = await fetch(
        `${baseUrl}/api/AirportLanding/${friendlyUrl}?t=preview`,
        { ...fetchTemplate }
      );
      landing = await landingResponse.json();
    } else {
      landing = await cacheFetch(
        `${baseUrl}/api/AirportLanding/${friendlyUrl}`
      );
    }
    if (
      t !== 'preview' &&
      landing.airport_frienldy_url &&
      landing.airport_frienldy_url != friendlyUrl
    ) {
      const [prefix, suffix] = extractPrefixSuffix(req.url);
      return {
        redirect: {
          destination: prefix + landing.airport_frienldy_url + suffix,
          permanent: true,
        },
      } as any;
    }
  } catch (e) {
    return {
      redirect: {
        destination: '/',
      },
    } as any;
  }
  const [prefix, suffix, supressFromHome] = extractPrefixSuffix(req.url);
  if (supressFromHome) {
    return {
      redirect: {
        destination: prefix + landing.airport_frienldy_url + suffix,
        permanent: true,
      },
    } as any;
  }
  if (landing.properties && CUSTOM_SLUGS[oriFriendlyUrl]) {
    if (CUSTOM_SLUGS[oriFriendlyUrl].price) {
      landing.properties.price = CUSTOM_SLUGS[oriFriendlyUrl].price;
    }
    if (CUSTOM_SLUGS[oriFriendlyUrl].seo_title) {
      landing.properties.seo_title = CUSTOM_SLUGS[oriFriendlyUrl].seo_title;
    }
    if (CUSTOM_SLUGS[oriFriendlyUrl].display_name) {
      landing.properties.display_name =
        CUSTOM_SLUGS[oriFriendlyUrl].display_name;
    }
    if (CUSTOM_SLUGS[oriFriendlyUrl].display_name) {
      landing.properties.display_name =
        CUSTOM_SLUGS[oriFriendlyUrl].display_name;
    }
  }

  const airports = await cacheFetch(`${baseUrl}/api/Airport`);

  return {
    props: {
      landing,
      airports,
      configInfo,
      deviceType: isMobile ? 'mobile' : 'desktop',
      mobileType: isIos ? 'ios' : 'android',
      checkin,
      checkout,
      fixedReviews: shuffleReviews(),
    },
  };
};

// pages/parking/[friendlyUrl].js
function Parking({
  landing,
  airports,
  searchAlternate,
  configInfo,
  deviceType,
  mobileType,
  checkin,
  checkout,
  icheckin,
  icheckout,
  baseUrlSite,
  applyChangeDates,
}) {
  const landingAirportInitials = landing?.properties?.airport_initials;
  const selectedAirport =
    landing?.airport_display_type == 1
      ? ''
      : JSON.stringify({
          airportinitials: landingAirportInitials,
          id: landing?.properties?.airport_id,
          value: `${landingAirportInitials} - ${landing?.properties?.airport_name}`,
        });
  const currentAirport = airports.result
    ?.filter((x) => x.airport_initials == landingAirportInitials)
    .pop();
  const metaKeywords = landing?.landing_seo_keywords ?? '';
  const refLanding = useRef(null);
  const [showBar, setShowBar] = useState(true);
  const [lowestPrice, setLowestPrice] = useState<number>(null);
  const router = useRouter();

  const dateNow = dayjs();
  return (
    <div className="relative">
      <TitleHead
        landing={landing}
        searchAlternate={searchAlternate}
        setLowestPrice={setLowestPrice}
      ></TitleHead>
      <Head>
        {landing?.activeFacilities === 0 && (
          <meta name="robots" content="noindex" />
        )}
        {metaKeywords && (
          <meta name="keywords" content={metaKeywords} key="keywords" />
        )}
        <link rel="canonical" href={canonicalUrl(baseUrlSite, router)} />
        {landing?.metainfo?.map((meta) => meta.content)}
        {landing?.properties?.landing_display_header}
        {landing?.schemas?.map((schema, index) => (
          <script
            type="application/ld+json"
            key={index}
            dangerouslySetInnerHTML={{
              __html: schema.schema_object,
            }}
          ></script>
        ))}
      </Head>
      <div className="md:min-h-full relative">
        {landing?.airport_display_type == 1 ? (
          <div
            style={{ backgroundColor: deviceType === 'mobile' && '#c5cfd3' }}
          >
            {deviceType !== 'mobile' && (
              <Image
                src={CDN2() + '/staticmyapp/bg-old-landing-city.webp'}
                className="z-0 w-full object-cover min-h-64 h-full absolute object-bottom"
                alt="City Background"
                fill
                style={{ objectFit: 'cover' }}
                quality={70}
              />
            )}
            <div className="container mx-auto px-4 py-8 z-10 relative">
              <Search
                searchType="city"
                title={applyTextSubstitution(landing?.properties?.seo_title, {
                  lowestPrice,
                })}
                selectedAirport={selectedAirport}
                deviceType={deviceType}
                checkin={checkin ?? undefined}
                checkout={checkout ?? undefined}
                applyChangeDates={applyChangeDates}
              />
            </div>
          </div>
        ) : (
          <div
            className="relative"
            style={{ backgroundColor: deviceType === 'mobile' && '#d0a785' }}
          >
            {deviceType === 'mobile' ? (
              <Image
                src={CDN2() + '/staticmyapp/bg-old-landing-mobile.webp'}
                className="z-0 w-full object-cover min-h-64 h-full absolute object-bottom"
                alt="Airport Background"
                fill
                priority
                quality={70}
              />
            ) : (
              <Image
                src={CDN2() + '/staticmyapp/bg-old-landing-desktop.webp'}
                className="z-0 w-full object-cover min-h-64 h-full absolute object-bottom"
                alt="Airport Background"
                fill
                priority
                quality={70}
              />
            )}
            <div className="container mx-auto px-4 py-6 z-10 relative">
              <div
                className="flex flex-col justify-center text-center text-gray-600"
                suppressHydrationWarning
              >
                <h1
                  className={`text-3xl w-full text-center mb-4 font-bold text-gray-600`}
                >
                  {applyTextSubstitution(landing?.properties?.display_name, {
                    lowestPrice,
                  })}
                </h1>
                <span>From</span>
                <span className="font-bold">
                  ${lowestPrice?.toFixed(2) ?? landing?.properties?.price} /day
                </span>
              </div>
              <Search
                searchType="airport"
                airports={airports}
                title={landing?.properties?.seo_title}
                selectedAirport={selectedAirport}
                deviceType={deviceType}
                checkin={icheckin}
                checkout={icheckout}
                isNewLanding={true}
                applyChangeDates={applyChangeDates}
              />
            </div>
          </div>
        )}
      </div>
      {landing?.airport_display_type == 1 ? (
        <></>
      ) : (
        <SearchAreaOld
          landing={landing}
          configInfo={configInfo}
          searchAlternate={searchAlternate}
          icheckin={icheckin}
          icheckout={icheckout}
        />
      )}
      <Suspense>
        <div
          className="landing high-content container md:max-w-5xl mx-auto px-4 h-screen overflow-hidden relative"
          ref={refLanding}
        >
          <article>
            <h2 className="text-2xl text-center px-4">
              {applyTextSubstitution(landing?.page_title, {
                lowestPrice,
              })}
            </h2>
            <div className="high-content flex flex-col gap-2">
              {landing?.sections
                ?.sort((a, b) => {
                  return a.order - b.order;
                })
                .map((section, index) =>
                  section.type !== 'Accordion' ? (
                    <section key={index}>
                      <header>
                        {section?.tag && <Title value={section?.tag} />}
                      </header>
                      <SectionItem
                        section={section}
                        configInfo={configInfo}
                        fallbackImageAlt={applyTextSubstitution(
                          landing?.page_title,
                          {
                            lowestPrice,
                          }
                        )}
                        parameters={{ lowestPrice }}
                      />
                    </section>
                  ) : (
                    <Collapse collapsible="header" key={index}>
                      <Panel header={section?.tag} key={index}>
                        <SectionItem
                          section={section}
                          configInfo={configInfo}
                          fallbackImageAlt={applyTextSubstitution(
                            landing?.page_title,
                            {
                              lowestPrice,
                            }
                          )}
                          parameters={{ lowestPrice }}
                        />
                      </Panel>
                    </Collapse>
                  )
                )}
            </div>
          </article>
          {showBar && (
            <div
              id="content-bar"
              className="absolute w-full left-0 flex items-center justify-center bottom-0 h-12 cursor-pointer"
              onClick={() => {
                setShowBar(false);
                refLanding.current.classList.remove('h-screen');
                refLanding.current.classList.remove('overflow-hidden');
              }}
            >
              <div className="mx-auto flex itens-center justify-center font-medium text-lg">
                Click to read more
              </div>
            </div>
          )}
        </div>
      </Suspense>
    </div>
  );
}

export default Parking;

const SearchAreaOldM = ({
  landing,
  searchAlternate,
  configInfo,
  icheckin,
  icheckout,
}: {
  landing: any;
  configInfo: any;
  searchAlternate: any;
  icheckin: dayjs.Dayjs;
  icheckout: dayjs.Dayjs;
}) => {
  const {
    searchStore,
  }: {
    searchStore: SearchStore;
  } = useMobxStores();

  // const [email, setEmail] = useState<string>('');

  // const doSubscribe = async () => {
  //   if (email) {
  //     await searchStore.subscribe(landing?.properties.airport_id, email);
  //     modalType({
  //       type: 'success',
  //       title: 'Success',
  //       okButtonProps: {
  //         className: 'bg-primary',
  //       },
  //       content: <p>Subscription for this was applied with success!</p>,
  //     });
  //   }
  //   setEmail('');
  // };

  useEffect(() => {
    searchStore.fetchSearchResult(null, {
      checkin: icheckin.toISOString(),
      checkout: icheckout.toISOString(),
      _$i: window.btoa(landing?.properties?.airport_id),
    });
  }, [landing, landing?.properties, searchStore, icheckin, icheckout]);

  const filteredFacilities =
    searchStore.filteredFacilities ?? searchAlternate?.result;

  return (
    <div className="bg-[#FEF3EB] flex flex-col">
      <div className={`md:mx-auto md:overflow-hidden grid grid-cols-1`}>
        {filteredFacilities?.length == 0 ? (
          <NoData
            imageNoData
            textNoData="Bummer! Nothing now, but new facilities coming soon!"
            bgImageWhite
            withSubscribe={true}
            airportId={landing?.properties.airport_id}
          />
        ) : (
          <>
            <CardList
              configInfo={configInfo}
              searchAlternate={searchAlternate}
              checkin={icheckin.format('YYYY-MM-DD')}
              checkout={icheckout.format('YYYY-MM-DD')}
            />
            <MiniInfos />
          </>
        )}
      </div>
    </div>
  );
};

const SearchAreaOld = observer(SearchAreaOldM);
