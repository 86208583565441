/* eslint-disable @next/next/no-img-element */
import { formatCurrency } from '@/helpers/format';
import usePrefix from '@/hooks/usePrefix';
import { useMobxStores } from '@/stores';
import {
  Affix,
  Button,
  Divider,
  Empty,
  Image,
  Popover,
  Rate,
  Skeleton,
  Tabs,
} from 'antd';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, useEffect, useRef } from 'react';
import ButtonStatus from './ButtonStatus';
import MiniHorizontal from './FacilityHighlights/MiniHorizontal';
import SidePopover from './FacilityHighlights/SidePopover';
import Vertical from './FacilityHighlights/Vertical';
import {
  DownCircleOutlined,
  EnvironmentFilled,
  UpCircleOutlined,
} from './Icons';
import SearchImage from './SearchImage';

import { isShowHighlights } from '@/helpers/highlights';
import FacilityTypeDeal from '@/utils/facilityTypeDeal';
import { CDN1, CDN2 } from '@/helpers/api';
import { mapRateSearchName, mapReviewStars } from '@/utils/tools';
import LowestPriceGuarantee from '@/components/LowestPriceGuarantee';
import { getParkingAtPath, getUrlCodeOrSlug } from '@/utils/newparkingatroutes';
import NeedMoreData from './NeedMoreData';
import { IAirport } from '@/types';
import {
  oldImages,
  blindBookingImages,
  hiddenValueImages,
  revealedOnBookingImages,
  unbrandedLocationImages,
  unlockSavingsImages,
} from '@/utils/unbrandedImages';
import { cardsAbTest } from '@/utils/gaTools';
import { isServer } from '@/utils/isServer';
import { setStorageCheckinCheckout } from '@/hooks/useLocalstorage';

function checkShowFull(facility_url_code: string) {
  return !facility_url_code?.toLocaleLowerCase().startsWith('arv');
}

const Card = ({
  configInfo,
  item,
  index,
  testScope,
  cardsRef,
  checkin,
  checkout,
  hasHighlight,
  deviceType,
}) => {
  const prefix = usePrefix();
  const router = useRouter();
  const { searchStore } = useMobxStores();
  const [showWhatParkBtn, setShowWhatParkBtn] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>('description');
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const { TabPane } = Tabs;

  const openMoreInformation = async (e): Promise<void> => {
    e.preventDefault();
    setShowWhatParkBtn(!showWhatParkBtn);
    setActiveKey('description');
    return;
  };

  const openViewHighlights = async (e): Promise<void> => {
    e.preventDefault();
    setShowWhatParkBtn(!showWhatParkBtn);
    setActiveKey('highlights');
    return;
  };

  function preventClick(e) {
    if (!e) e = window.event;
    e.stopPropagation();
    return;
  }

  useEffect(() => {
    sessionStorage.removeItem('checkin');
    sessionStorage.removeItem('checkout');
  }, []);

  let lastUnbrandedIndex = null;

  const getUnbrandedImage = (testScope) => {
    let imagesArray;

    if (testScope === 1) {
      imagesArray = blindBookingImages;
    } else if (testScope === 2) {
      imagesArray = hiddenValueImages;
    } else if (testScope === 3) {
      imagesArray = revealedOnBookingImages;
    } else if (testScope === 4) {
      imagesArray = unbrandedLocationImages;
    } else if (testScope === 5) {
      imagesArray = unlockSavingsImages;
    } else {
      imagesArray = oldImages;
    }

    let randomIndex = index % imagesArray.length;
    if (randomIndex === lastUnbrandedIndex) {
      randomIndex = (randomIndex + 1) % imagesArray.length;
    }
    lastUnbrandedIndex = randomIndex;

    return imagesArray[randomIndex];
  };

  let imageUrl = item.facility_is_image_uploaded
    ? `${CDN1}/upload/${configInfo.environment}/${item.facility_id}`
    : getUnbrandedImage(testScope);

  const arriveLogo = item.highlights.find((x) => x.type === 'arrive_logo_url');
  if (arriveLogo) {
    imageUrl = arriveLogo.description;
  }

  return item.date_sold_out > 0 ? (
    <>
      {' '}
      <div
        ref={(el) => (cardsRef.current[index] = el)}
        key={item.facility_id}
        className={`relative max-w-2xl mx-auto bg-white ${
          item.selected ? 'shadow-2xl' : ''
        }`}
      >
        <div className="max-w-2xl relative shadow-md border rounded-md overflow-hidden md:min-h-64 h-auto  grid grid-cols-1 md:grid-cols-7 place-items-start">
          <div className="relative bg-gray-100 col md:col-span-3 h-52 w-full md:w-[17rem] pointer-events-none cursor-not-allowed opacity-50">
            <SearchImage
              src={imageUrl}
              native={!!arriveLogo}
              errorImage={getUnbrandedImage(testScope)}
              quality={100}
              placeholder="blur"
              className="z-0  w-full h-full"
              fill
              alt={`Airport: ${item.facility_lot} Background`}
              key={testScope + '_' + item.facility_id}
            />
          </div>
          <div className="col-span-4 w-full ">
            <div className="grid grid-cols-3 w-full mb-2 pointer-events-none cursor-not-allowed opacity-50">
              <div className="flex flex-col w-full col-span-2 px-4 md:px-0 self-center mx-auto">
                <Rate
                  allowHalf
                  disabled
                  value={mapReviewStars(item.facility_review_avg)}
                  className="text-left !text-base !mt-[1.375rem]"
                />
                <div className="w-80 md:w-64">
                  {/* <p className="text-sm w-full mt-2 text-black font-semibold">
                    <span className="text-primary">
                      <DollarCircleFilled /> Cheap Rate
                    </span>{' '}
                    {item.facility_review_avg}
                    -star Parking
                  </p> */}
                  <h2 className="text-lg font-semibold text-left w-full truncate mt-0.5">
                    {item.facility_lot}
                  </h2>

                  <p className="text-xs">
                    <EnvironmentFilled /> {item.facility_airport_distance}{' '}
                    {item.facility_airport_distance === 1 ? 'mile' : 'miles'}
                  </p>
                  <div className="flex flex-row mt-4">
                    <p
                      className={`
                      text-white font-semibold rounded-md w-12 text-sm text-center ${
                        item.facility_review_avg >= 4.5
                          ? 'bg-green-900'
                          : item.facility_review_avg >= 4.0
                          ? 'bg-green-500'
                          : 'bg-lime-500'
                      }
                      `}
                    >
                      {item.facility_review_avg.toFixed(1)}
                      <span>/5</span>
                    </p>
                    <p className="bg-white text-sm font-medium rounded-md">
                      <span
                        className={`bg-white ${
                          item.facility_review_avg >= 4.5
                            ? 'text-green-900'
                            : item.facility_review_avg >= 4.0
                            ? 'text-green-500'
                            : 'text-lime-500'
                        }`}
                      >
                        &nbsp;{' '}
                        {item.facility_review_avg >= 4.5
                          ? 'Excellent!'
                          : item.facility_review_avg >= 4.0
                          ? 'Very Good!'
                          : 'Good!'}
                      </span>
                    </p>
                  </div>
                  <p className="!text-xs mb-4 mt-1.5">
                    ({item.num_review}{' '}
                    {item.num_review === 1 ? 'review' : 'reviews'})
                  </p>
                  {isShowHighlights(item.highlights) ? (
                    <>
                      <MiniHorizontal
                        facility={item}
                        highlights={item.highlights}
                        size="small"
                      />
                      {/* <p
                        className="text-[#03A1CF] cursor-pointer text-[0.625rem]"
                        onClick={openViewHighlights}
                      >
                        View highlights
                      </p> */}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {item.facilityParkingType == 3 ||
              item.facilityParkingType == 4 ? (
                <div className="flex flex-col h-full md:justify-self-end justify-end self-center pr-4 w-full md:w-auto">
                  <h3 className="text-2xl font-medium text-right text-deal">
                    {formatCurrency({
                      value: item.facility_solid_selling_price
                        ? item.facility_solid_selling_price
                        : item.facility_afee,
                      locale: 'en-US',
                      currency: 'USD',
                    })}
                  </h3>
                  <small className="text-right text-xs block">
                    {item.facilityParkingType == 3
                      ? 'Sleep and Park'
                      : 'Monthly Park'}
                  </small>
                </div>
              ) : (
                <div className="flex flex-col h-full md:justify-self-end justify-end self-center pr-4 w-full md:w-auto">
                  <h3 className="text-2xl font-medium text-right text-deal">
                    {formatCurrency({
                      value: item.facility_selling_price,
                      locale: 'en-US',
                      currency: 'USD',
                    })}
                  </h3>
                  <small className="text-right text-xs block">
                    {mapRateSearchName(item)}
                  </small>
                </div>
              )}
            </div>
            <div className="hidden md:flex md:flex-col">
              <ButtonStatus
                status={searchStore.facilityStatus(item)}
                facility_url_code={item.facility_url_code}
                minDays={item.facility_min_days}
                maxDays={item.facility_max_days}
                configInfo={configInfo}
              />
            </div>
            <div className="self-end flex flex-col md:hidden px-4 col-span-3 disabled mb-2">
              <ButtonStatus
                status={searchStore.facilityStatus(item)}
                facility_url_code={item.facility_url_code}
                minDays={item.facility_min_days}
                maxDays={item.facility_max_days}
                configInfo={configInfo}
              />
            </div>
          </div>
          <div className="bg-gray-100 w-full col-span-4 md:col-span-7 text-center justify-center md:justify-end items-center">
            <button
              className="h-10 w-full border-none bg-transparent text-xs text-[#03A1CF] !bg-gray-100 focus:!bg-gray-100 hover:!bg-gray-100 flex flex-row items-center justify-between pr-6"
              onClick={openMoreInformation}
            >
              <p className="text-gray-300 opacity-50 !text-xs ml-4 cursor-default ">
                {item.facility_url_code}
              </p>
              <div className="flex flex-row">
                <p className="mr-2">What is the parking address?</p>

                {showWhatParkBtn ? (
                  <UpCircleOutlined />
                ) : (
                  <DownCircleOutlined />
                )}
              </div>
            </button>
          </div>
          <div
            className={`overflow-hidden col-span-full ${
              showWhatParkBtn ? 'h-auto ' : 'absolute '
            }  flex flex-col  items-center  w-full opacity-50`}
          >
            <div
              className={`w-full flex mt-2  ${
                showWhatParkBtn ? 'visible ' : 'hidden'
              } transition delay-300 ease-in-out`}
              onClick={preventClick}
            >
              <Tabs
                activeKey={activeKey}
                onChange={(k) => setActiveKey(k)}
                type="card"
                size="large"
                className="tab-reservation-2"
              >
                <TabPane
                  key="description"
                  tab={
                    <span className="text-base flex flex-col items-center align-center">
                      <p>Address</p>
                    </span>
                  }
                >
                  <div className="flex flex-col w-full">
                    <div className="px-4 mb-2">
                      {item.facility_branded == 1 ? (
                        <>
                          <h3 className="text-left my-2">
                            {item.facility_address}
                          </h3>
                          <h3 className="text-left my-2">
                            {item.facility_short_description}
                          </h3>
                        </>
                      ) : (
                        <span>{configInfo.unbrandedAddress}</span>
                      )}
                      {item.facility_branded != 1 &&
                        item.location_guaranteed &&
                        item.location_guaranteed.length > 0 && (
                          <div>
                            <h3 className="font-bold text-left my-2">
                              Guaranteed parking at one of these great lots
                            </h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              {item.location_guaranteed.map(
                                (guaranteedLocation) => (
                                  <>
                                    <div className="border border-gray p-2 rounded-md">
                                      <Rate
                                        allowHalf
                                        disabled
                                        value={guaranteedLocation.stars}
                                        className="text-left !text-xs mb-2"
                                      />
                                      <p className="text-white font-semibold rounded-md w-12 text-sm text-center bg-deal/90">
                                        {guaranteedLocation.range.toFixed(1)}+
                                      </p>
                                      <h3 className="font-bold text-left mt-2 line-clamp-1">
                                        {guaranteedLocation.name}
                                      </h3>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </TabPane>
                {isShowHighlights(item.highlights) ? (
                  <TabPane
                    key="highlights"
                    tab={
                      <span className="text-base flex flex-col items-center align-center">
                        <p>Highlights</p>
                      </span>
                    }
                  >
                    <div className="flex flex-col !w-full">
                      <Vertical
                        facility={item}
                        highlights={item.highlights}
                        size="small"
                      />
                    </div>
                  </TabPane>
                ) : (
                  <></>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        key={item.facility_id}
        ref={(el) => (cardsRef.current[index] = el)}
        className={`relative max-w-2xl mx-auto bg-white z-0 ${
          item.selected ? 'shadow-2xl' : ''
        } `}
      >
        {item.facility_id == searchStore.cheapestFacility && (
          <>
            <div
              className={`z-10 absolute left-0 top-0 cursor-default flex flex-shrink-0 items-center
            w-0 h-0 bg-transparent  border-solid border-r-[85px] border-t-[85px] border-r-transparent border-t-primary rounded-t-md rounded-r-none
            `}
            ></div>
            <p className="p-0.5 -rotate-45 absolute left-2 top-0 z-20 text-white font-bold">
              Best <br /> Price!
            </p>
          </>
        )}
        {item.facility_is_express_deal > 0 && (
          <small
            className={`rounded-md flex flex-row items-center h-6 w-40 text-start font-semibold text-xs italic absolute z-10 text-white right-4 top-4 p-2 cursor-default bg-primary`}
          >
            <Image
              src={CDN2() + '/staticmyapp/cheapparkdeal.svg'}
              width={16}
              height={16}
              alt="Express Deal"
              preview={false}
              className=""
            />

            <p className="mx-auto">{FacilityTypeDeal(item)}</p>
          </small>
        )}
        <Link
          href={`${prefix}/${getParkingAtPath(item)}/${getUrlCodeOrSlug(item)}`}
          key={item.facility_id + index}
          passHref
          legacyBehavior
        >
          <div
            className={`cursor-pointer max-w-2xl relative rounded-md overflow-hidden md:min-h-64 grid grid-cols-1 md:grid-cols-7 place-items-start border border-primary/80 shadow-lg `}
          >
            <div className="relative bg-gray-100 col md:col-span-3 h-52 w-full md:w-[17rem]">
              <SearchImage
                src={imageUrl}
                native={!!arriveLogo}
                errorImage={getUnbrandedImage(testScope)}
                quality={100}
                placeholder="blur"
                className="z-0  w-full h-full"
                fill
                alt={`Airport: ${item.facility_lot} Background`}
                key={testScope + '_' + item.facility_id}
              />
            </div>
            <div className="col-span-4 w-full">
              <div className="grid grid-cols-3 w-full mb-2 md:mb-0">
                <div className="flex flex-col w-full col-span-2 px-4 md:px-0 self-center mx-auto">
                  <Rate
                    allowHalf
                    disabled
                    value={mapReviewStars(item.facility_review_avg)}
                    className="text-left !text-base !mt-[1.375rem]"
                  />
                  <div className="w-80 md:w-[23rem] min-h-[130px] md:min-h-[165px]">
                    {/* <p className="text-sm w-full mt-2 text-black font-semibold">
                      <span className="text-primary">
                        <DollarCircleFilled /> Cheap Rate
                      </span>{' '}
                      {item.facility_review}
                      -star Parking
                    </p> */}
                    <h2 className="text-lg font-semibold text-left w-3/4 truncate mt-2.5">
                      {item.facility_lot}
                    </h2>
                    <p className="text-xs">
                      <EnvironmentFilled /> {item.facility_airport_distance}{' '}
                      {item.facility_airport_distance === 1 ? 'mile' : 'miles'}
                    </p>
                    {checkShowFull(item.facility_url_code) && (
                      <>
                        <div className="flex flex-row mt-4">
                          <p
                            className={`
                      text-white font-semibold rounded-md w-12 text-sm text-center ${
                        item.facility_review_avg >= 4.5
                          ? 'bg-green-900'
                          : item.facility_review_avg >= 4.0
                          ? 'bg-green-500'
                          : 'bg-lime-500'
                      }
                      `}
                          >
                            {item.facility_review_avg.toFixed(1)}
                            <span>/5</span>
                          </p>
                          <p className="bg-white text-sm font-medium rounded-md">
                            <span
                              className={`bg-white ${
                                item.facility_review_avg >= 4.5
                                  ? 'text-green-900'
                                  : item.facility_review_avg >= 4.0
                                  ? 'text-green-500'
                                  : 'text-lime-500'
                              }`}
                            >
                              &nbsp;{' '}
                              {item.facility_review_avg >= 4.5
                                ? 'Excellent!'
                                : item.facility_review_avg >= 4.0
                                ? 'Very Good!'
                                : 'Good!'}
                            </span>
                          </p>
                        </div>
                        <p className="!text-xs mb-4 mt-1.5">
                          ({item.num_review}{' '}
                          {item.num_review === 1 ? 'review' : 'reviews'})
                        </p>
                      </>
                    )}
                    {isShowHighlights(item.highlights) ? (
                      <>
                        <Popover
                          content={
                            <SidePopover
                              facility={item}
                              highlights={item.highlights}
                              size="small"
                            />
                          }
                          trigger={deviceType === 'desktop' ? 'hover' : 'click'}
                          overlayClassName={`${
                            deviceType !== 'desktop' && 'search-list-popover'
                          }`}
                        >
                          <MiniHorizontal
                            facility={item}
                            highlights={item.highlights}
                            size="small"
                            style={{ display: 'inline-flex' }}
                          />
                        </Popover>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {!item.facility_disable_lowest_price && (
                  <div className="absolute right-3.5 top-[14.5rem] md:top-12">
                    <LowestPriceGuarantee />
                  </div>
                )}
                {item.facilityParkingType == 3 ||
                item.facilityParkingType == 4 ? (
                  <div className="flex flex-col h-full md:justify-self-end  justify-end self-center pr-4 w-full md:w-auto">
                    <h3 className="text-2xl font-medium text-right text-primary">
                      {formatCurrency({
                        value: item.facility_solid_selling_price
                          ? item.facility_solid_selling_price
                          : item.facility_afee,
                        locale: 'en-US',
                        currency: 'USD',
                      })}
                    </h3>
                    <small className="text-right text-xs block">
                      {item.facilityParkingType == 3
                        ? 'Sleep and Park'
                        : 'Monthly Park'}
                    </small>
                  </div>
                ) : (
                  <div className="flex flex-col h-full md:justify-self-end  justify-end self-center pr-4 w-full md:w-auto">
                    <h3 className="text-2xl font-medium text-right text-primary">
                      {formatCurrency({
                        value: item.facility_selling_price,
                        locale: 'en-US',
                        currency: 'USD',
                      })}
                    </h3>
                    <small className="text-right text-xs block">
                      {mapRateSearchName(item)}
                    </small>
                  </div>
                )}
              </div>
            </div>
            <div className="bg-gray-100 w-full col-span-4 md:col-span-7 text-center justify-center md:justify-end items-center">
              <button
                className="h-10 w-full border-none bg-transparent text-xs text-[#03A1CF] !bg-gray-100 focus:!bg-gray-100 hover:!bg-gray-100 flex flex-row items-center justify-between pr-6"
                onClick={openMoreInformation}
              >
                <p className="text-gray-300 opacity-50 !text-xs ml-4 cursor-default ">
                  {item.facility_url_code}
                </p>
                <div className="flex flex-row">
                  <p className="mr-2">What is the parking address?</p>

                  {showWhatParkBtn ? (
                    <UpCircleOutlined />
                  ) : (
                    <DownCircleOutlined />
                  )}
                </div>
              </button>
            </div>
            <div
              className={`overflow-hidden col-span-full ${
                showWhatParkBtn ? 'h-auto ' : 'absolute '
              }  flex flex-col  items-center  w-full  `}
            >
              <div
                className={`w-full flex mt-2  ${
                  showWhatParkBtn ? 'visible ' : 'hidden'
                } transition delay-300 ease-in-out`}
                onClick={preventClick}
              >
                <Tabs
                  activeKey={activeKey}
                  onChange={(k) => setActiveKey(k)}
                  type="card"
                  size="large"
                  className="tab-reservation-2"
                >
                  <TabPane
                    key="description"
                    tab={
                      <span className="text-base flex flex-col items-center align-center">
                        <p>Address</p>
                      </span>
                    }
                  >
                    <div className="flex flex-col w-full">
                      <div className="px-4">
                        {item.facility_branded == 1 ? (
                          <>
                            <h3 className="text-left my-2">
                              {item.facility_address}
                            </h3>
                            <h3 className="text-left my-2">
                              {item.facility_short_description}
                            </h3>
                          </>
                        ) : (
                          <span>{configInfo.unbrandedAddress}</span>
                        )}
                        {item.facility_branded != 1 &&
                          item.location_guaranteed &&
                          item.location_guaranteed.length > 0 && (
                            <div>
                              <h3 className="font-bold text-left my-2">
                                Guaranteed parking at one of these great lots
                              </h3>
                              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {item.location_guaranteed.map(
                                  (guaranteedLocation) => (
                                    <>
                                      <div className="border border-gray p-2 rounded-md">
                                        <Rate
                                          allowHalf
                                          disabled
                                          value={guaranteedLocation.stars}
                                          className="text-left !text-xs mb-2"
                                        />
                                        <p className="text-white font-semibold rounded-md w-12 text-sm text-center bg-deal/90">
                                          {guaranteedLocation.range.toFixed(1)}+
                                        </p>
                                        <h3 className="font-bold text-left mt-2 line-clamp-1">
                                          {guaranteedLocation.name}
                                        </h3>
                                      </div>
                                    </>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                      <Divider />
                      <Button
                        type="primary"
                        className="w-28 h-10 rounded-full -mt-2 mb-4 self-end mr-2 flex justify-center items-center !pt-0"
                        size="large"
                        href={`${prefix}/${getParkingAtPath(
                          item
                        )}/${getUrlCodeOrSlug(item)}`}
                      >
                        <span className="font-bold">
                          {item.facilityParkingType == 3
                            ? 'Sleep & Park'
                            : item.facilityParkingType == 4
                            ? 'Monthly Park'
                            : 'Park Here'}
                        </span>
                      </Button>
                    </div>
                  </TabPane>
                  {isShowHighlights(item.highlights) ? (
                    <TabPane
                      key="highlights"
                      tab={
                        <span className="text-base flex flex-col items-center align-center">
                          <p>Highlights</p>
                        </span>
                      }
                    >
                      <div className="flex flex-col !w-full">
                        <div className="">
                          <Vertical
                            facility={item}
                            highlights={item.highlights}
                            size="small"
                          />
                        </div>
                        <Divider />
                        <Button
                          type="primary"
                          className="w-28 h-10 rounded-full -mt-2 mb-4 self-end mr-2 flex justify-center items-center !pt-0"
                          size="large"
                          href={`${prefix}/${getParkingAtPath(
                            item
                          )}/${getUrlCodeOrSlug(item)}`}
                        >
                          <span className="font-bold">
                            {item.facilityParkingType == 3
                              ? 'Sleep & Park'
                              : 'Park Here'}
                          </span>
                        </Button>
                      </div>
                    </TabPane>
                  ) : (
                    <></>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

function SearchList({
  configInfo,
  checkin,
  checkout,
  deviceType,
  testScope,
  searchAlternate,
  currentAirport,
}: {
  configInfo: any;
  checkin: string;
  checkout: string;
  testScope?: number;
  deviceType: string;
  searchAlternate: any;
  currentAirport?: IAirport;
}) {
  const { searchStore } = useMobxStores();
  const cardsRef = useRef<any>([]);

  const hasHighlight = searchStore.filteredFacilities?.some(
    (x) => x.facility_branded == 1
  );

  const filteredFacilities =
    searchStore.filteredFacilities ?? searchAlternate?.result;

  const showLowestPriceMessage = filteredFacilities?.some(
    (item) => item.facility_disable_lowest_price === 0
  );
  useEffect(() => {
    if (!isServer) {
      setStorageCheckinCheckout(checkin, checkout);
    }
  }, [checkin, checkout]);
  return (
    // <Skeleton
    //   active
    //   loading={searchStore.isLoading}
    //   className="max-w-2xl mx-auto"
    // >
    <>
      {filteredFacilities?.map((item, index) => {
        return (
          <div key={index} className="mb-4 mt-2 px-2">
            <Card
              configInfo={configInfo}
              item={item}
              testScope={testScope}
              index={index}
              cardsRef={cardsRef}
              checkin={checkin}
              checkout={checkout}
              hasHighlight={hasHighlight}
              deviceType={deviceType}
            />
          </div>
        );
      })}
      {showLowestPriceMessage && (
        <p className="text-[0.625rem] ml-2 mt-1.5 mb-4 md:mb-2 md:text-sm">
          *We’ll pay you the difference if you find the same deal cheaper after
          booking.
        </p>
      )}
      {searchStore.filteredFacilities?.length === 0 && (
        <NeedMoreData airport={currentAirport} />
      )}
    </>
  );
}

export default observer(SearchList);
