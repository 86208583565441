import { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { isServer } from '@/utils/isServer';

function getSessionStorageValue(key, defaultValue) {
  // getting stored value
  if (isServer) {
    return defaultValue;
  }
  const saved = sessionStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getSessionStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

function getLocalStorageValue(key, defaultValue) {
  // getting stored value
  if (isServer) {
    return defaultValue;
  }
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getLocalStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export function setStorageCheckinCheckout(checkin, checkout) {
  sessionStorage.setItem(
    'checkincheckout',
    JSON.stringify({ checkin, checkout })
  );
}

export const useStorageCheckinCheckout = () => {
  const curcheckincheckout = getSessionStorageValue('checkincheckout', null);
  if (curcheckincheckout) {
    const today = dayjs().format('YYYY-MM-DD');
    if (
      curcheckincheckout.checkin < today &&
      curcheckincheckout.checkout < today
    ) {
      setStorageCheckinCheckout(
        today,
        dayjs().add(1, 'day').format('YYYY-MM-DD')
      );
    }
  }
  const [checkincheckout, setCheckinCheckout] = useSessionStorage(
    'checkincheckout',
    {
      checkin: dayjs().format('YYYY-MM-DD'),
      checkout: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    }
  );
  return [
    checkincheckout,
    setCheckinCheckout,
    (checkin: Dayjs, checkout: Dayjs) => {
      if (!checkin.isValid() || !checkout.isValid()) {
        setCheckinCheckout({
          checkin: dayjs().format('YYYY-MM-DD'),
          checkout: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        });
      } else {
        setCheckinCheckout({
          checkin: checkin.format('YYYY-MM-DD'),
          checkout: checkout.format('YYYY-MM-DD'),
        });
      }
    },
  ];
};
