import Image from 'next/image';
import React, { useEffect, Suspense, useState } from 'react';
import SearchLandingExtraB from './SearchLandingExtraB';
import NoData from '@/components2/NoData';
import EnhancedTextExtra from './Landing/EnhancedTextExtra';
import SimpleClickExtraB from './SimpleClickExtraB';
import ReviewsLanding from './ReviewsLanding';
import { formatCurrency } from '@/helpers/format';
import { Button, Collapse } from 'antd';
import PeopleAlso from './PeopleAlso';
import GuideForTravelling from './GuideForTravelling';
import SectionItem from './Landing/SectionItem';
import { DownOutlined, MinusOutlined, PlusOutlined, UpOutlined } from './Icons';
import { CDN1, CDN2 } from '@/helpers/api';
import StoreBtnsExtraB from './StoreBtnsExtraB';
import Title from './Landing/Title';
import TitleHead from '@/components/TitleHead';
import Link from 'next/link';
import { observer } from 'mobx-react';
import HighlightsExtraB from '@/components/HighlightsExtraB';
import Head from 'next/head';
import DownloadApp from './DownloadApp';
import SearchList from '@/components2/SearchList';
import SearchStore from '@/stores/SearchStore';
import { useMobxStores } from '@/stores';
import GoogleNews from './GoogleNews';
import { applyTextSubstitution } from '@/helpers/linkhelper';
import { findCheapestFacility } from '@/utils/tools';
import { useRouter } from 'next/router';
import { getParkingAtPath, getUrlCodeOrSlug } from '@/utils/newparkingatroutes';
import usePrefix from '@/hooks/usePrefix';
import dayjs, { Dayjs } from 'dayjs';
import { canonicalUrl } from '@/helpers/history';

const { Panel } = Collapse;

function fixBlogImages(
  blogList: Array<{ name: string; image: string; url: string; date: string }>
) {
  const list = blogList.filter(
    (item) =>
      item.image != null &&
      (item.image.endsWith('images/default.png') ||
        item.image.endsWith('images/default2.png') ||
        item.image.endsWith('images/default3.png'))
  );
  if (list.length > 0) {
    list[0].image = `${CDN2()}/staticmyapp/default-landing.webp`;
    for (let i = 1; i < list.length; i++) {
      list[i].image = `${CDN2()}/staticmyapp/default-landing${
        (i % 2) + 2
      }.webp`;
    }
  }
}

function ExtraLandingComponentB({
  landing,
  deviceType,
  selectedAirport,
  checkin,
  checkout,
  airports,
  searchAlternate,
  icheckin,
  icheckout,
  configInfo,
  fixedReviews,
  googleNews,
  baseUrlSite,
  testScope,
  applyChangeDates,
}) {
  fixBlogImages(landing?.extra?.blogs_1);
  fixBlogImages(landing?.extra?.blogs_2);
  const [lowestPrice, setLowestPrice] = useState<number>(null);
  const metaKeywords = landing?.landing_seo_keywords ?? '';

  const {
    searchStore,
  }: {
    searchStore: SearchStore;
  } = useMobxStores();
  let backGroundImage = CDN2() + '/staticmyapp/landing-banner-generic.jpg';
  if (landing.extra.extra_image) {
    backGroundImage = `${CDN1}/upload/${
      configInfo.isSandBoxEnvironment
        ? 1
        : configInfo.isProductionEnvironment
        ? 2
        : 0
    }/${landing.extra.extra_image}`;
  }
  const [clickSearch, setclickSearch] = useState(false);
  const prefix = usePrefix();
  const router = useRouter();
  const { openLowestPrice } = router.query;

  function hundleClickSearch() {
    setclickSearch(true);
  }
  // TODO: Nao precisa mais de reload, trocar a logica
  useEffect(() => {
    if (openLowestPrice && searchAlternate?.result?.length > 0) {
      const cheapest = findCheapestFacility(searchAlternate?.result);

      const parkingUrl = `${prefix}/${getParkingAtPath(
        cheapest
      )}/${getUrlCodeOrSlug(cheapest)}}`;

      router.replace(parkingUrl);
    }
  }, [openLowestPrice, searchAlternate]);

  const doOpenLowestPrice = async (checkin: Dayjs, checkout: Dayjs) => {
    await searchStore.fetchSearchResult(null, {
      checkin: checkin.toISOString(),
      checkout: checkout.toISOString(),
      _$i: window.btoa(landing?.properties?.airport_id),
    });
    const cheapest = findCheapestFacility(searchStore.filteredFacilities);

    const parkingUrl = `${prefix}/${getParkingAtPath(
      cheapest
    )}/${getUrlCodeOrSlug(cheapest)}`;

    router.replace(parkingUrl);
  };

  return (
    <div
      className={`relative ${
        landing?.extra?.extra_enabled == true ? ` landing-extra` : ``
      }`}
    >
      <TitleHead
        landing={landing}
        searchAlternate={searchAlternate}
        setLowestPrice={setLowestPrice}
      ></TitleHead>
      <Head>
        {metaKeywords && (
          <meta name="keywords" content={metaKeywords} key="keywords" />
        )}
        <link rel="canonical" href={canonicalUrl(baseUrlSite, router)} />
        {landing?.metainfo?.map((meta) => meta.content)}
        {landing?.properties?.landing_display_header}
        {landing?.schemas?.map((schema, index) => (
          <script
            type="application/ld+json"
            key={index}
            dangerouslySetInnerHTML={{
              __html: schema.schema_object,
            }}
          ></script>
        ))}
      </Head>
      <div className="md:h-96 relative">
        {landing?.airport_display_type == 1 ? (
          <div
            style={{ backgroundColor: deviceType === 'mobile' && '#c5cfd3' }}
          >
            {deviceType !== 'mobile' && (
              <Image
                src={CDN2() + '/staticmyapp/bg-search-city.svg'}
                className="z-0 w-full object-cover min-h-64 h-full absolute object-bottom"
                alt="City Background"
                fill
                style={{ objectFit: 'cover' }}
                quality={70}
              />
            )}
            <div className="container mx-auto px-4 py-8 z-10 relative">
              <SearchLandingExtraB
                searchType="city"
                title={landing?.properties?.seo_title}
                selectedAirport={selectedAirport}
                applyChangeDates={applyChangeDates}
                openLowestPrice={doOpenLowestPrice}
                deviceType={deviceType}
                checkin={checkin ?? undefined}
                checkout={checkout ?? undefined}
              />
            </div>
          </div>
        ) : (
          <div>
            <Image
              src={backGroundImage}
              className="brightness-75 opacity-90"
              alt="Airport Background"
              fill
              style={{ objectFit: 'cover' }}
              quality={70}
              fetchPriority="high"
              loading="eager"
            />
            <div className="container mx-auto px-4 py-6 z-10 relative">
              <div className="flex flex-col justify-center text-center">
                <h1
                  className={`text-3xl md:text-[3.375rem] leading-none w-full text-center mb-2 font-bold text-white drop-shadow-lg shadow-black`}
                >
                  {landing?.properties?.display_name}
                </h1>
                <span className="hidden md:block font-bold text-white mb-4">
                  Starting From $
                  {lowestPrice?.toFixed(2) ?? landing?.properties?.price} /day
                </span>
              </div>
              <SearchLandingExtraB
                searchType="airport"
                airports={airports}
                title={landing?.properties?.seo_title}
                selectedAirport={selectedAirport}
                deviceType={deviceType}
                applyChangeDates={applyChangeDates}
                openLowestPrice={doOpenLowestPrice}
                checkin={icheckin}
                checkout={icheckout}
                isNewLanding={true}
                clickSearch={hundleClickSearch}
                buttonBackground={`${
                  landing?.extra?.button_background
                    ? landing?.extra?.button_background
                    : '#3D68A5'
                }`}
                buttonTextColor={`${
                  landing?.extra?.button_text_color
                    ? landing?.extra?.button_text_color
                    : '#FFFFFF'
                }`}
              />
            </div>
          </div>
        )}
        {landing?.extra?.extra_enabled == true && (
          <Suspense>
            <StoreBtnsExtraB googleStars={4.5} appleStars={4.5} />
          </Suspense>
        )}
      </div>
      <Suspense>
        {clickSearch ? (
          <>
            {!(checkin || checkout) && landing?.extra?.extra_enabled && (
              <HighlightsExtraB />
            )}
          </>
        ) : (
          <>{landing?.extra?.extra_enabled == true && <HighlightsExtraB />}</>
        )}
      </Suspense>

      {landing?.airport_display_type == 1 ? (
        <></>
      ) : (
        <SearchArea
          deviceType={deviceType}
          landing={landing}
          configInfo={configInfo}
          icheckin={icheckin}
          icheckout={icheckout}
          searchAlternate={searchAlternate}
          testScope={testScope}
        />
      )}

      {landing?.extra?.extra_enabled == true && (
        <Suspense>
          {landing?.extra?.extra_enabled == true && (
            <EnhancedTextExtra
              className="text-xs md:text-2xl mt-8 px-4 md:px-24"
              value={applyTextSubstitution(landing?.extra?.extra_first_offer, {
                lowestPrice,
              })}
            />
          )}

          {clickSearch &&
            (checkin || checkout) &&
            landing?.extra?.extra_enabled && <HighlightsExtraB />}
          <ReviewsLanding reviews={fixedReviews} />
          <SimpleClickExtraB />
          <DownloadApp landingPage={true} />
          <div className="flex justify-center pt-8 pb-16 px-4">
            <div className="flex flex-col bg-primary rounded-2xl h-auto md:p-6 max-w-[22rem] w-full md:max-w-6xl">
              <h2 className="text-white text-xl md:text-6xl mb-8 text-center font-bold mt-4">
                Save more - become a member!
              </h2>
              <p className="text-base md:text-2xl text-white text-center mx-auto">
                {formatCurrency({
                  value: configInfo.membershipOldPrice,
                  locale: 'en-US',
                  currency: 'USD',
                })}
                /year
              </p>
              <Button
                size="large"
                data-test-id="park_now"
                // type="primary"
                block
                className="bg-white text-secondary rounded-full text-sm md:text-3xl font-semibold hover:bg-opacity-75 self-center my-6 md:my-12 w-1/2 md:w-full md:max-w-xs h-12 md:h-16"
              >
                <a href="https://www.onairparking.com/myreservation/membership">
                  Become A Member
                </a>
              </Button>
            </div>
          </div>
          <PeopleAlso items={landing?.extra?.blogs_1} />
          <GoogleNews googleNews={googleNews} />

          <div className="flex flex-col mt-24 mb-12 md:mb-20 px-4 md:px-24">
            <EnhancedTextExtra
              className="text-sm md:text-2xl md:self-center"
              value={applyTextSubstitution(landing?.extra?.extra_second_offer, {
                lowestPrice,
              })}
            />
            <Button
              size="large"
              data-test-id="park_now"
              type="primary"
              block
              className="rounded-full text-lg md:text-3xl font-semibold hover:bg-opacity-75 w-1/2 md:max-w-xs h-12 md:h-16 self-center mt-16"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              Check Availability
            </Button>
          </div>

          <div>
            <EnhancedTextExtra
              className="text-sm md:text-2xl px-10 md:px-10"
              value={applyTextSubstitution(landing?.extra?.extra_blog_2_text, {
                lowestPrice,
              })}
            />
            <div className="block md:hidden">
              <GuideForTravelling items={landing?.extra?.blogs_2} isMobile />
            </div>
            <div className="hidden md:block">
              <GuideForTravelling items={landing?.extra?.blogs_2} />
            </div>
          </div>
        </Suspense>
      )}
      <Suspense>
        <div
          className={`high-content ${
            landing?.extra?.extra_enabled == true ? `bg-orange-50` : `bg-white`
          } flex flex-col justify-center gap-2 px-4 md:px-24 h-auto pt-16 pb-4 -mt-24 -z-10`}
        >
          <article>
            <h4 className="text-2xl text-center px-4">
              {applyTextSubstitution(landing?.page_title, { lowestPrice })}
            </h4>
            <div className="high-content flex flex-col gap-2">
              {landing?.sections
                ?.sort((a, b) => {
                  return a.order - b.order;
                })
                .map((section, index) =>
                  section.type !== 'Accordion' ? (
                    <section key={index}>
                      <header>
                        {section?.tag && <Title value={section?.tag} />}
                      </header>
                      <div
                        className={`${
                          landing?.extra
                            ? `
                        flex flex-col md:flex-row justify-center items-center
                      `
                            : ``
                        }
                    `}
                      >
                        <SectionItem
                          section={section}
                          configInfo={configInfo}
                          fallbackImageAlt={applyTextSubstitution(
                            landing?.page_title,
                            { lowestPrice }
                          )}
                          secIndex={index}
                          parameters={{ lowestPrice }}
                        />
                        {landing?.extra?.extra_enabled == true && (
                          <>
                            <p className="hidden md:block">
                              <Image
                                src={CDN2() + '/staticmyapp/interrogation.png'}
                                width={122}
                                height={160}
                                alt="icon"
                              />
                            </p>
                            <p className="md:hidden block -mt-8">
                              <Image
                                src={CDN2() + '/staticmyapp/interrogation.png'}
                                width={61}
                                height={80}
                                alt="icon"
                              />
                            </p>
                          </>
                        )}
                      </div>
                    </section>
                  ) : (
                    <Collapse
                      bordered={
                        landing?.extra?.extra_enabled == true ? false : true
                      }
                      className="--header-border"
                      key={index}
                      expandIconPosition="right"
                      expandIcon={({ isActive }) => (
                        <span>
                          {isActive ? (
                            <MinusOutlined className="text-black" />
                          ) : (
                            <PlusOutlined className="text-black" />
                          )}
                        </span>
                      )}
                    >
                      <Panel header={<b>{section?.tag}</b>} key={index}>
                        <SectionItem
                          section={section}
                          secIndex={index}
                          configInfo={configInfo}
                          fallbackImageAlt={applyTextSubstitution(
                            landing?.page_title,
                            { lowestPrice }
                          )}
                          parameters={{ lowestPrice }}
                        />
                      </Panel>
                    </Collapse>
                  )
                )}
            </div>
          </article>
          {landing?.extra?.extra_enabled == true && (
            <Button
              size="large"
              data-test-id="park_now"
              type="primary"
              block
              className="rounded-full text-lg md:text-3xl font-semibold hover:bg-opacity-75 w-1/2 md:max-w-xs h-12 md:h-16 self-center mt-8 mb-16"
            >
              <Link href="https://www.onairparking.com/faq">More FAQ's</Link>
            </Button>
          )}
        </div>
      </Suspense>
    </div>
  );
}

export default ExtraLandingComponentB;

const SearchAreaM = ({
  landing,
  configInfo,
  icheckin,
  icheckout,
  deviceType,
  searchAlternate,
  testScope,
}) => {
  const {
    searchStore,
  }: {
    searchStore: SearchStore;
  } = useMobxStores();

  useEffect(() => {
    searchStore.fetchSearchResult(null, {
      checkin: icheckin.toISOString(),
      checkout: icheckout.toISOString(),
      _$i: window.btoa(landing?.properties?.airport_id),
    });
  }, [landing, landing?.properties, searchStore, icheckin, icheckout]);

  const filteredFacilities =
    searchStore.filteredFacilities ?? searchAlternate?.result;

  return (
    <>
      <div className={`bg-orange-50 flex flex-col pb-8 md:pb-16 `}>
        {filteredFacilities?.length === 0 ? (
          <NoData
            imageNoData
            textNoData="Bummer! Nothing now, but new facilities coming soon!"
            bgImageWhite
            withSubscribe={true}
            airportId={landing?.properties?.airport_id}
          />
        ) : (
          <div
            className={`md:mx-auto md:overflow-hidden xl:px-24 p-4 ${
              filteredFacilities?.length >= 2 &&
              'grid grid-cols-1 2xl:grid-cols-2 gap-x-4'
            }  ${filteredFacilities?.length === 1 && 'justify-center'}`}
          >
            <SearchList
              configInfo={configInfo}
              searchAlternate={searchAlternate}
              checkin={icheckin.format('YYYY-MM-DD')}
              checkout={icheckout.format('YYYY-MM-DD')}
              deviceType={deviceType}
              testScope={testScope}
            />
          </div>
        )}
      </div>
    </>
  );
};

const SearchArea = observer(SearchAreaM);
