import usePrefix from '@/hooks/usePrefix';
import { googleMapsApiKey } from '@/utils/googleMaps';
import { ArrowRightOutlined } from '@components/Icons';
import { Autocomplete, LoadScriptNext } from '@react-google-maps/api';
import { useMobxStores } from '@stores/index';
import { isServer } from '@utils/isServer';
import { AutoComplete as AutoCompleteAntd, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { IAirportValue, ICityValue, ISearch } from '../types';
import { DatePicker } from './DatePicker';
import { modalType } from './Modal';
import Image from 'next/image';
import { CDN2 } from '@/helpers/api';
import { pushLayerEvent } from '@/utils/gtag';

const { RangePicker } = DatePicker;
const Libraries: any = ['geometry', 'places'];

function getSessionStoreCity() {
  try {
    return JSON.parse(sessionStorage.getItem('city'));
  } catch (e) {
    return null;
  }
}

function SearchLandingExtraB({
  searchType,
  airports,
  title,
  selectedAirport,
  deviceType,
  checkin,
  checkout,
  applyChangeDates,
  openLowestPrice,
  isNewLanding,
  clickSearch,
  buttonBackground,
  buttonTextColor,
}: ISearch): ReactElement {
  const { searchStore } = useMobxStores(),
    router = useRouter(),
    prefix = usePrefix(),
    searchTitle = title?.replace(/\s\|.*/gm, ''),
    // isSearchPage = router.pathname.includes('search'),
    [dateRange, setDaterange] = useState({
      checkIn: checkin ? checkin : dayjs().format(),
      checkOut: checkout ? checkout : dayjs().add(1, 'day').format(),
    }),
    [airportValue, setAirportValue] = useState<IAirportValue>(), //airport
    [cityAutocomplete, setCityAutocomplete] = useState(null),
    [cityValue, setCityValue] = useState<ICityValue>(getSessionStoreCity()), //city
    autocompleteAirportOptions = airports?.result?.map((airport) => {
      return {
        value: `${airport.airport_initials} - ${airport.airport_name}`,
        id: airport.airport_id,
        airportinitials: airport.airport_initials,
      };
    });

  const autocompleteCruiseOptions = airports?.result
    ?.filter((x) => x.airport_display_type == 2)
    .map((airport) => {
      return {
        value: `${airport.airport_initials} - ${airport.airport_name}`,
        id: airport.airport_id,
        airportinitials: airport.airport_initials,
        lat: airport.airport_latitude,
        lng: airport.airport_longitude,
      };
    });

  const [calendarView, setCalendarView] = useState<[Dayjs, Dayjs]>([
    checkin ? dayjs(checkin) : dayjs(),
    checkout ? dayjs(checkout) : dayjs().add(1, 'day'),
  ]);
  const calendarChange = (a) => {
    if (a && a[0]?.isAfter(calendarView[1])) {
      setDaterange({ checkIn: a[0], checkOut: a[0].add(1, 'day') });
      setCalendarView([a[0], a[0].add(1, 'day')]);
    } else {
      setDaterange({ checkIn: a[0], checkOut: a[1] });
    }
  };

  function handleAutocompleteSelect({
    _value,
    option,
  }: {
    _value;
    option;
  }): void {
    setAirportValue({
      value: option.value,
      id: option.id,
      airportinitials: option.airportinitials,
    });
    if (!isServer && router.pathname.includes('search')) {
      sessionStorage.setItem(
        'airport',
        JSON.stringify({
          value: option.value,
          id: option.id,
          airportinitials: option.airportinitials,
        })
      );
    }
  }

  function handleFilterAutocomplete({
    inputValue,
    option,
  }: {
    inputValue;
    option;
  }): boolean {
    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }

  function disabledDate(current): boolean {
    return current && current < dayjs().startOf('day');
  }

  function handleSearchValidations(): boolean {
    if (searchType === 'airport' || searchType === 'cruise') {
      if (airportValue?.value === null || airportValue?.value === '') {
        modalType({
          type: 'error',
          title: 'Alert',
          content: 'What is the Airport?',
        });
        return false;
      }
    } else {
      if (
        typeof cityValue?.formatted_address === 'undefined' ||
        cityValue.formatted_address === ''
      ) {
        modalType({
          type: 'error',
          title: 'Alert',
          content: 'Please inform a valid US place or address.',
        });
        return true;
      }
    }

    if (dateRange.checkIn === '' || dateRange.checkOut === '') {
      modalType({
        type: 'error',
        title: 'Alert',
        content: 'What is the Start and End date?',
      });

      return false;
    }
    if (dayjs(dateRange.checkIn).isSame(dateRange.checkOut)) {
      modalType({
        type: 'error',
        title: 'Alert',
        content: `Please choose a End date that is later than ${dayjs(
          dateRange.checkIn
        ).format('MM/DD/YYYY')}`,
      });
      return false;
    }

    if (dayjs(dateRange.checkOut).isBefore(dateRange.checkIn)) {
      modalType({
        type: 'error',
        title: 'Alert',
        content: `Please choose a End date that is later than ${dayjs(
          dateRange.checkOut
        ).format('MM/DD/YYYY')}`,
      });
      return false;
    }
  }

  function handleOnPlaceChanged() {
    if (cityAutocomplete) {
      const { formatted_address, geometry, html_attributions }: ICityValue =
        cityAutocomplete.getPlace();
      sessionStorage.setItem(
        'city',
        JSON.stringify({
          formatted_address,
          geometry,
          html_attributions,
        })
      );
      setCityValue({
        formatted_address,
        geometry,
        html_attributions,
      });
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  }

  function handleAirportCityRedirect(): void {
    let routerUrl = '';
    const query = {};
    // redirect
    console.log(router.route, 'route');
    if (
      (searchType === 'airport' || searchType === 'cruise') &&
      router.route.indexOf('parking/[slug]') > 0
    ) {
      if (applyChangeDates) {
        applyChangeDates(dayjs(dateRange.checkIn), dayjs(dateRange.checkOut));
      } else {
        const p = router.route?.replace('[slug]', router.query?.slug as string);
        router.push({
          pathname: p,
          query: Object.assign(query, {
            checkin: dayjs(dateRange.checkIn).format('YYYY-MM-DD'),
            checkout: dayjs(dateRange.checkOut).format('YYYY-MM-DD'),
          }),
        });
      }
      return;
    }

    if (searchType === 'airport' || searchType === 'cruise') {
      routerUrl = prefix + '/reservation/search';
      Object.assign(query, {
        initials: airportValue.airportinitials,
        airport: airportValue.value,
        _$i: !isServer && window.btoa(airportValue?.id?.toString()),
      });
    } else if (searchType === 'city') {
      Object.assign(query, {
        city: cityValue?.formatted_address,
        lat: JSON.parse(sessionStorage.getItem('city')).geometry.location.lat,
        lng: JSON.parse(sessionStorage.getItem('city')).geometry.location.lng,
      });
      routerUrl = prefix + '/reservation/citysearch';
    } else if (searchType === 'monthly') {
      routerUrl = router.route?.replace(
        '[friendlyUrl]',
        router.query?.friendlyUrl as string
      );
      Object.assign(query, {
        search: true,
        city: cityValue.formatted_address,
        lat: JSON.parse(sessionStorage.getItem('city')).geometry.location.lat,
        lng: JSON.parse(sessionStorage.getItem('city')).geometry.location.lng,
      });
    }

    router.push({
      pathname: routerUrl,
      query: Object.assign(query, {
        checkin: dayjs(dateRange.checkIn).format('YYYY-MM-DD'),
        checkout: dayjs(dateRange.checkOut).format('YYYY-MM-DD'),
      }),
    });
  }

  function handleAirportCityRedirectToLowestPrice(): void {
    let routerUrl = '';
    const query = {};
    // redirect
    console.log(router.route, 'route');
    if (
      (searchType === 'airport' || searchType === 'cruise') &&
      router.route.indexOf('parking/[slug]') > 0
    ) {
      if (openLowestPrice) {
        applyChangeDates(dayjs(dateRange.checkIn), dayjs(dateRange.checkOut));
        openLowestPrice(dayjs(dateRange.checkIn), dayjs(dateRange.checkOut));
      } else {
        const p = router.route?.replace('[slug]', router.query?.slug as string);
        router.push({
          pathname: p,
          query: Object.assign(query, {
            checkin: dayjs(dateRange.checkIn).format('YYYY-MM-DD'),
            checkout: dayjs(dateRange.checkOut).format('YYYY-MM-DD'),
            openLowestPrice: true,
          }),
        });
      }
      return;
    }

    if (searchType === 'airport' || searchType === 'cruise') {
      routerUrl = prefix + '/reservation/search';
      Object.assign(query, {
        initials: airportValue.airportinitials,
        airport: airportValue.value,
        _$i: !isServer && window.btoa(airportValue?.id?.toString()),
      });
    } else if (searchType === 'city') {
      Object.assign(query, {
        city: cityValue?.formatted_address,
        lat: JSON.parse(sessionStorage.getItem('city')).geometry.location.lat,
        lng: JSON.parse(sessionStorage.getItem('city')).geometry.location.lng,
      });
      routerUrl = prefix + '/reservation/citysearch';
    } else if (searchType === 'monthly') {
      routerUrl = router.route?.replace(
        '[friendlyUrl]',
        router.query?.friendlyUrl as string
      );
      Object.assign(query, {
        search: true,
        city: cityValue.formatted_address,
        lat: JSON.parse(sessionStorage.getItem('city')).geometry.location.lat,
        lng: JSON.parse(sessionStorage.getItem('city')).geometry.location.lng,
      });
    }

    router.push({
      pathname: routerUrl,
      query: Object.assign(query, {
        checkin: dayjs(dateRange.checkIn).format('YYYY-MM-DD'),
        checkout: dayjs(dateRange.checkOut).format('YYYY-MM-DD'),
      }),
    });
  }

  function handleAutoCompleteAirportChange(): void {
    !isServer && sessionStorage.removeItem('airport');
    setAirportValue({
      value: '',
      id: null,
      airportinitials: '',
    });
  }

  function handleAutoCompleteCityChange(e): void {
    setCityValue(e.target.value);
    !isServer && sessionStorage.removeItem('city');
  }

  function handleRangePickerOnChange(dateStrings): void {
    console.log('dateString', dateStrings);
    const [checkIn, checkOut] = dateStrings;
    setDaterange({ checkIn, checkOut });
    setCalendarView([dayjs(checkIn), dayjs(checkOut)]);
  }

  function handleMobileDateOnChange({ type, date }: { type; date }): void {
    if (type === 'checkin') {
      setDaterange({ ...dateRange, checkIn: date });
    } else {
      setDaterange({ ...dateRange, checkOut: date });
    }
  }

  useEffect(() => {
    if (
      (searchType === 'airport' || searchType === 'cruise') &&
      !selectedAirport
    ) {
      setAirportValue({
        value: router.query?.airport?.toString() || null,
        id: router.query._$i
          ? !isServer && Number(window.atob(router.query?._$i?.toString()))
          : null,
        airportinitials: router.query?.initials?.toString() || null,
      });
    }
    if (selectedAirport) {
      const data = JSON.parse(selectedAirport);
      setAirportValue({
        value: data?.value || null,
        id: Number(data?.id) || null,
        airportinitials: data?.airportinitials || null,
      });
    }
  }, [router.query, searchType, selectedAirport]);

  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (buttonClicked) {
      window.scrollTo({ top: 250, behavior: 'smooth' });
    }
  }, [buttonClicked]);

  return (
    <div className="flex flex-wrap items-center search-range-picker justify-center">
      {isNewLanding ? (
        <></>
      ) : (
        <>
          {title && (
            <h1
              className={`text-3xl w-full text-center mb-8 font-bold ${
                deviceType === 'mobile' && 'text-gray-800'
              }
          `}
            >
              {searchTitle}
            </h1>
          )}
        </>
      )}

      <div
        className={`md:bg-white rounded-full md:shadow-xl md:pr-4 flex flex-auto flex-wrap items-center justify-start`}
      >
        {isNewLanding ? (
          <>
            {searchType === 'airport' && (
              <div className=" bg-white md:bg-transparent rounded-full relative w-full md:w-2/6  py-2 pl-4 pr-6 mb-3 md:mb-0 text-gray-700 leading-tight focus:outline-none h-14 md:h-16 flex flex-row items-center justify-center">
                <AutoCompleteAntd
                  key={selectedAirport}
                  className="w-full border-white hover:border-white px-0 "
                  options={autocompleteAirportOptions}
                  filterOption={(inputValue, option) =>
                    handleFilterAutocomplete({ inputValue, option })
                  }
                  defaultValue={
                    router.query?.airport
                      ? router.query?.airport?.toString()
                      : selectedAirport
                      ? JSON.parse(selectedAirport)?.value
                      : null
                  }
                  onSelect={(value, option) =>
                    handleAutocompleteSelect({ _value: value, option })
                  }
                  disabled={router.pathname.includes('parking')}
                >
                  <Input
                    data-test-id="airport"
                    autoFocus
                    placeholder="Where do you want to park?"
                    className="border-none text-[#2C2C2C] hover:border-white px-0 ml-2 focus:outline-none"
                    onChange={handleAutoCompleteAirportChange}
                    bordered={false}
                    suffix={
                      <Image
                        src={`${CDN2()}/staticmyapp/gps.png`}
                        width={18}
                        height={18}
                        alt="Calendar"
                      />
                    }
                  />
                </AutoCompleteAntd>
              </div>
            )}
          </>
        ) : (
          <>
            {searchType === 'airport' && (
              <div className="bg-white md:bg-transparent rounded-full relative w-full md:w-2/6  py-2 pl-4 pr-6 mb-3 md:mb-0 text-gray-700 leading-tight focus:outline-none h-10 flex flex-row items-center justify-center">
                <AutoCompleteAntd
                  key={selectedAirport}
                  className="w-full border-white hover:border-white px-0 "
                  options={autocompleteAirportOptions}
                  filterOption={(inputValue, option) =>
                    handleFilterAutocomplete({ inputValue, option })
                  }
                  defaultValue={
                    router.query?.airport
                      ? router.query?.airport?.toString()
                      : selectedAirport
                      ? JSON.parse(selectedAirport)?.value
                      : null
                  }
                  onSelect={(value, option) =>
                    handleAutocompleteSelect({ _value: value, option })
                  }
                  disabled={router.pathname.includes('parking')}
                >
                  <Input
                    data-test-id="airport"
                    autoFocus
                    placeholder="Where do you want to park?"
                    className="border-none text-[#2C2C2C] hover:border-white px-0 ml-2 focus:outline-none"
                    onChange={handleAutoCompleteAirportChange}
                    bordered={false}
                  />
                </AutoCompleteAntd>
              </div>
            )}
          </>
        )}
        {searchType === 'cruise' && (
          <div className="bg-white md:bg-transparent rounded-full relative w-full md:w-2/6  py-2 pl-4 pr-6 mb-3 md:mb-0 text-gray-700 leading-tight focus:outline-none h-10 flex flex-row items-center justify-center">
            <AutoCompleteAntd
              key={selectedAirport}
              className="w-full border-white hover:border-white px-0 "
              options={autocompleteCruiseOptions}
              filterOption={(inputValue, option) =>
                handleFilterAutocomplete({ inputValue, option })
              }
              defaultValue={
                router.query?.airport
                  ? router.query?.airport?.toString()
                  : selectedAirport
                  ? JSON.parse(selectedAirport)?.value
                  : null
              }
              onSelect={(value, option) =>
                handleAutocompleteSelect({ _value: value, option })
              }
              disabled={router.pathname.includes('parking')}
            >
              <Input
                data-test-id="airport"
                autoFocus
                placeholder="Where do you want to park?"
                className="border-none text-[#2C2C2C] hover:border-white px-0 ml-2 focus:outline-none"
                onChange={handleAutoCompleteAirportChange}
                bordered={false}
              />
            </AutoCompleteAntd>
          </div>
        )}
        {(searchType === 'monthly' || searchType === 'city') && (
          <div className="bg-white md:bg-transparent rounded-full relative w-full md:w-2/6 pl-6 pr-6 mb-3 md:mb-0 text-gray-700 leading-tight focus:outline-none h-10 flex flex-col items-center justify-center">
            <LoadScriptNext
              googleMapsApiKey={googleMapsApiKey}
              libraries={Libraries}
              onLoad={() => searchStore.setIsLoadedGoogle(true)}
            >
              <Autocomplete
                onLoad={(autocomplete) => setCityAutocomplete(autocomplete)}
                onPlaceChanged={handleOnPlaceChanged}
                restrictions={{ country: 'us' }}
                fields={['geometry.location', 'formatted_address']}
                className="w-full border-white hover:border-white px-0"
              >
                <input
                  data-test-id="address"
                  placeholder="Type a places or address"
                  className="border-white text-[#2C2C2C] hover:border-white px-0 pb-1 pt-1  w-full focus:outline-none"
                  defaultValue={
                    router.query?.city
                      ? router.query?.city?.toString()
                      : selectedAirport
                      ? JSON.parse(selectedAirport)?.value
                      : null
                  }
                  type="text"
                />
              </Autocomplete>
            </LoadScriptNext>
          </div>
        )}
        <div className="hidden md:grid grid-cols-1 rounded-full w-full md:w-2/6">
          <RangePicker
            bordered={false}
            data-test-id="range_picker"
            size="small"
            className="w-full"
            format="MM/DD/YYYY"
            // defaultValue={[
            //   typeof checkin !== 'undefined'
            //     ? dayjs(dayjs(checkin), 'MM/DD/YYYY')
            //     : null,
            //   typeof checkout !== 'undefined'
            //     ? dayjs(dayjs(checkout), 'MM/DD/YYYY')
            //     : null,
            // ]}
            value={calendarView}
            onCalendarChange={calendarChange}
            onChange={(_dates, dateStrings) =>
              handleRangePickerOnChange(dateStrings)
            }
            separator={
              <Image
                src={`${CDN2()}/staticmyapp/calendar2.png`}
                width={45}
                height={45}
                alt="Calendar"
                className="z-0 text-[#2C2C2C]"
              />
            }
            suffixIcon={
              <Image
                src={`${CDN2()}/staticmyapp/calendar2.png`}
                width={18}
                height={18}
                alt="Calendar"
                className="z-0 text-[#2C2C2C]"
              />
            }
            disabledDate={disabledDate}
          />
        </div>
        <div
          className={`md:bg-transparent py-6 md:py-0 rounded-md md:hidden flex w-full items-start 
        ${
          isNewLanding
            ? 'flex-row justify-between'
            : 'bg-white flex-col justify-start'
        }            
          `}
        >
          {/* <div
          className={`bg-white flex-col justify-start md:bg-transparent py-6 md:py-0 rounded-md md:hidden flex w-full items-start`}
        > */}
          <div
            className={` ${
              isNewLanding
                ? 'flex justify-center items-center bg-white h-14 rounded-full w-[10.313rem]'
                : 'h-14'
            }`}
          >
            {/* <label className="px-4 font-bold text-[0.625rem]" htmlFor="checkIn">
              Start Date
            </label> */}
            <div className="px-4 flex w-full">
              {isNewLanding ? (
                <label
                  className="inline-block relative w-full"
                  style={{
                    lineHeight: 0,
                  }}
                >
                  <Input
                    data-test-id="mob_start_date"
                    type="date"
                    defaultValue={dayjs(dateRange.checkIn).format('YYYY-MM-DD')}
                    pattern="\d{4}-\d{2}-\d{2}"
                    placeholder="Starting parking at"
                    onChange={(evt) => {
                      handleMobileDateOnChange({
                        type: 'checkin',
                        date: dayjs(evt.target.value).format('MM/DD/YYYY'),
                      });
                    }}
                    bordered={false}
                    width={100}
                    className={`${
                      isNewLanding ? 'newLandingInputDate z-10' : ''
                    }`}
                  />

                  <div className="px-3 mt-0 text-sm flex flex-row justify-between items-center w-full">
                    <Image
                      src={`${CDN2()}/staticmyapp/calendar2.png`}
                      width={18}
                      height={18}
                      alt="Calendar"
                      className="z-0 text-[#2C2C2C]"
                    />
                    <p className="text-[#2C2C2C]">
                      {dayjs(dateRange.checkIn)
                        .format('MM-DD-YYYY')
                        .toUpperCase()}{' '}
                    </p>
                  </div>
                </label>
              ) : (
                <Input
                  data-test-id="mob_start_date"
                  type="date"
                  defaultValue={dayjs(dateRange.checkIn).format('YYYY-MM-DD')}
                  pattern="\d{4}-\d{2}-\d{2}"
                  placeholder="Starting parking at"
                  onChange={(evt) => {
                    handleMobileDateOnChange({
                      type: 'checkin',
                      date: dayjs(evt.target.value).format('MM/DD/YYYY'),
                    });
                  }}
                  bordered={false}
                  width={100}
                  className={` ${isNewLanding ? '-mt-2' : ''}`}
                />
              )}
            </div>
          </div>
          <div
            className={` ${
              isNewLanding
                ? 'flex justify-center items-center bg-white h-14 rounded-full w-[10.313rem]'
                : 'h-14'
            }`}
          >
            {/* <label
              className="px-4 font-bold text-[0.625rem]"
              htmlFor="checkOut"
            >
              End Date
            </label> */}
            <div className="px-4 flex w-full">
              {isNewLanding ? (
                <label
                  className="inline-block relative w-full"
                  style={{
                    lineHeight: 0,
                  }}
                >
                  <Input
                    data-test-id="mob_start_date"
                    type="date"
                    defaultValue={dayjs(dateRange.checkOut).format(
                      'YYYY-MM-DD'
                    )}
                    pattern="\d{4}-\d{2}-\d{2}"
                    placeholder="Starting parking at"
                    onChange={(evt) => {
                      handleMobileDateOnChange({
                        type: 'checkout',
                        date: dayjs(evt.target.value).format('MM/DD/YYYY'),
                      });
                    }}
                    bordered={false}
                    width={100}
                    className={`${
                      isNewLanding ? 'newLandingInputDate z-10' : ''
                    }`}
                  />

                  <div className="px-3 mt-0 text-sm flex flex-row justify-between items-center w-full">
                    <Image
                      src={`${CDN2()}/staticmyapp/calendar2.png`}
                      width={18}
                      height={18}
                      alt="Calendar"
                      className="z-0"
                    />
                    <p className="text-[#2C2C2C]">
                      {dayjs(dateRange.checkOut)
                        .format('MM-DD-YYYY')
                        .toUpperCase()}{' '}
                    </p>
                  </div>
                </label>
              ) : (
                <Input
                  data-test-id="mob_end_date"
                  type="date"
                  inputMode="none"
                  defaultValue={dayjs(dateRange.checkOut).format('YYYY-MM-DD')}
                  pattern="\d{4}-\d{2}-\d{2}"
                  placeholder="End parking at"
                  onChange={(evt) => {
                    handleMobileDateOnChange({
                      type: 'checkout',
                      date: dayjs(evt.target.value).format('MM/DD/YYYY'),
                    });
                  }}
                  bordered={false}
                  width={100}
                  className={` ${
                    isNewLanding ? 'newLandingInputDate z-10' : ''
                  }`}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end w-2/6">
          <button
            data-test-id="park_now"
            className={`hidden
        h-10 md:mt-0 md:ml-2 text-white text-base rounded-full p-2 hover:bg-opacity-75 focus:outline-none md:w-48 md:flex flex-row items-center justify-center font-bold
        ${isNewLanding ? `bg-[#3D68A5]` : 'bg-[#F47607]'}
        `}
            onClick={() => {
              if (handleSearchValidations() === false) return false;
              handleAirportCityRedirect();
              setButtonClicked(true);
              clickSearch();
            }}
          >
            {isNewLanding ? (
              'Check Availability'
            ) : (
              <>
                {router.pathname.includes('search')
                  ? 'Search again'
                  : 'Park Here'}
              </>
            )}
          </button>
        </div>
      </div>
      <div className="flex flex-row pt-2 md:hidden  w-full items-start justify-between">
        <button
          data-test-id="park_now"
          className={`h-14 w-[10.313rem] text-sm rounded-full p-1.5 hover:bg-opacity-75 focus:outline-none flex flex-row items-center justify-center uppercase font-bold
        ${!isNewLanding && 'bg-[#F47607] text-white'}
        `}
          style={{ backgroundColor: buttonBackground }}
          onClick={() => {
            if (handleSearchValidations() === false) return false;
            handleAirportCityRedirect();
            if (isNewLanding) {
              setButtonClicked(true);
              clickSearch();
            }
          }}
        >
          {isNewLanding ? (
            <p style={{ color: buttonTextColor }}>Check Availability</p>
          ) : (
            <>
              {router.pathname.includes('search')
                ? 'Search again'
                : 'Park Here'}
            </>
          )}
        </button>
        <button
          data-test-id="park_now"
          className={`h-14 w-[10.313rem] text-sm rounded-full p-1.5 hover:bg-opacity-75 focus:outline-none flex flex-row items-center justify-center uppercase font-bold
        ${!isNewLanding && 'bg-[#F47607] text-white'}
        `}
          style={{ backgroundColor: buttonBackground }}
          onClick={() => {
            if (handleSearchValidations() === false) return false;
            handleAirportCityRedirectToLowestPrice();
            pushLayerEvent('getLowestPrice');
          }}
        >
          <p style={{ color: buttonTextColor }}>Get the Lowest Rate</p>
        </button>
      </div>
    </div>
  );
}

export default observer(SearchLandingExtraB);
